<template>
    <div v-if="dataLoaded">
        <div class="header-container white">
            <div class="entry-short" v-bind:style="{ backgroundImage: 'url(' + cmsPageHeaderImage + ')' }">
                <div class="context">
                    <h1>{{ localize(cmsPage.attributes.title) }}</h1>
                    <h2>{{ localize(cmsPage.attributes.subtitle) }}</h2>
                </div>
            </div>

            <div class="breadcrumbs-container">
                <div class="breadcrumbs">
                    <router-link to="/">Home</router-link><img class="breadcrumb-arrow" src="~@/assets/arrow_right.svg">
                    <router-link :to="breadcrumb.URL" v-for="breadcrumb in cmsPage.attributes.Breadcrumbs"
                        v-if="localizeAlt(breadcrumb, 'Title')" v-bind:key="breadcrumb.URL">
                        {{ localizeAlt(breadcrumb, 'Title') }}<img class="breadcrumb-arrow" src="~@/assets/arrow_right.svg">
                    </router-link>
                    <span><strong>{{ localize(cmsPage.attributes.title) }}</strong></span>
                </div>
            </div>
        </div>

        <PageBuilder v-if="cmsPage.attributes.type == 'pagebuilder'" :cmsBlocks="cmsPage.attributes.cmsBlocks" />

        <TextBlock v-if="cmsPage.attributes.type == 'text'"
            :cmsBlock="{ text: cmsPage.attributes.content, wysiwygStyle: 'white' }" />

    </div>
</template>

<script>
import { cmsPageQuery, globalOptionQuery } from '@/queries'
import { config } from '@/config'
import { scrollToElement } from '@/helper'

import PageBuilder from '@/components/PageBuilder'
import TextBlock from '@/components/PageBuilder/TextBlock'
import router from '../router'

export default {
    name: 'CmsPage',

    components: {
        PageBuilder,
        TextBlock
    },

    data() {
        return {
            cmsPage: null
        }
    },

    props: [
        'route'
    ],

    apollo: {
        cmsPage: {
            query: cmsPageQuery,
            variables() {
                return {
                    title: this.routeTitle
                }
            },
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true,
            update: data => {
                if (!data || !data.cmsPages || !data.cmsPages.data || data.cmsPages.data.length === 0) {
                    router.replace({ name: '404' }) // Replace with your 404 route name
                    return;
                }
                return data.cmsPages.data[0]
            }
        },

        defaultCmsPageHeader: {
            query: globalOptionQuery,
            variables: {
                optionId: 'default-cms-page-header'
            },
            fetchPolicy: 'cache-first',
            update: data => data.globalOptions.data[0]
        }

    },

    watch: {
        cmsPage: {
            handler() {
                if (window.location.hash.length > 0) {
                    this.$nextTick(() => {
                        var anchor = document.getElementById(window.location.hash.replace('#', ''))
                        if (anchor) {
                            scrollToElement(anchor)
                        }
                    })
                }
            }
        }
    },

    metaInfo() {
        var meta = []
        var style = []

        if (this.metaTitle) {
            meta.push({
                vmid: 'og:title',
                name: 'og:title',
                content: this.metaTitle
            })
        }
        if (this.metaDescription) {
            meta.push({
                vmid: 'description',
                name: 'description',
                content: this.metaDescription
            })
            meta.push({
                vmid: 'og:description',
                name: 'og:description',
                content: this.metaDescription
            })
        }
        if (this.metaImage) {
            meta.push({
                vmid: 'og:image',
                name: 'og:image',
                content: this.metaImage
            })
        }
        if (this.css) {
            style.push({
                vmid: 'cms-page-style',
                type: 'text/css',
                innerHTML: this.css
            })
        }

        return {
            title: this.metaTitle,
            meta: meta,
            style: style
        }
    },

    computed: {
        routeTitle() {
            return this.$route.params.title ? this.$route.params.title : this.route
        },

        dataLoaded() {
            return this.cmsPage != null
        },

        metaTitle() {
            if (this.cmsPage) {
                if (this.cmsPage.attributes.metaTitle) {
                    var title = this.localize(this.cmsPage.attributes.metaTitle) ? this.localize(this.cmsPage.attributes.metaTitle) : this.localize(this.cmsPage.attributes.title)
                    return config.globalPageTitle + ' - ' + title
                }
                return config.globalPageTitle + ' - ' + this.localize(this.cmsPage.attributes.title)

            } else {
                return null
            }
        },

        metaDescription() {
            if (this.cmsPage) {
                return this.cmsPage.attributes.metaDescription ? this.localize(this.cmsPage.attributes.metaDescription) : this.localize(this.cmsPage.attributes.subtitle)
            } else {
                return null
            }
        },

        metaImage() {
            if (this.cmsPage && this.cmsPage.attributes.metaImage && this.localize(this.cmsPage.attributes.metaImage).data !== null) {
                return this.getImageUrl(this.localize(this.cmsPage.attributes.metaImage).data.attributes.url)
            } else {
                return null
            }
        },

        cmsPageHeaderImage() {
            return this.defaultCmsPageHeader ? this.getImageUrl(this.localize(this.defaultCmsPageHeader.attributes.image).data.attributes.url) : ''
        },

        css() {
            if (this.cmsPage) {
                return this.cmsPage.attributes.css ? this.cmsPage.attributes.css : null
            }
        }
    },
}
</script>

<style lang="scss" scoped>          .header-container {
              display: flex;
              flex-direction: column;
              box-sizing: inherit !important;

              @include breakpoint('mobile') {
                  flex-direction: column-reverse;

                  .entry-short {
                      background-image: none !important;
                      background-color: white;
                      height: auto;

                      h1 {
                          font-size: 28px;
                          line-height: 1em;
                          color: $timberGreen;
                      }

                      h2 {
                          color: $timberGreen;
                      }

                      .context {
                          position: unset;
                      }
                  }
              }

              .breadcrumbs-container {
                width: 100%;
                  .breadcrumbs {
                      background-color: white;
                      padding: getSpacing('padding-small-vertical', 'desktop') getSpacing('padding-default-horizontal', 'desktop');
                      color: black;
                      box-sizing: content-box;
                      line-height: 16px;
                      font-size: 16px;
                      max-width: $container-width;
                      margin: 0 auto;

                      @include breakpoint('mobile') {
                          padding: getSpacing('padding-small-vertical', 'desktop') getSpacing('padding-default-horizontal', 'mobile'); //0px 15px 0px 40px ;
                          min-height: 70px;
                      }

                      @include breakpoint('mobile') {
                          padding-top: 150px;
                      }

                      a {
                          color: #273B41;
                          font-size: 16px;
                      }

                      .breadcrumb-arrow {
                          width: 7px !important;
                          box-sizing: content-box;
                          filter: invert(1);
                          vertical-align: middle;
                          padding: 0px 10px 3px 10px;
                      }
                  }
              }
          }</style>